export const selectAllOption = {
    label: 'All',
    value: ''
}

export const getOptionsWithSelectAll =  <T>(options:T[] ) => {
    return [
        selectAllOption,
        ...options
    ]
}

export const booleanOptions = [
    {
        label: 'Yes',
        value: "yes"
    },
    {
        label: 'No',
        value: "no"
    }
]

export const getSelectedBooleanValue = (value: string) => value === "yes" 