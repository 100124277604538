import {defaultBreadcrumbs} from 'src/app/models/screen-prop'
import {DeliveryOrderScreen} from './pages/DeliveryOrder/Screen'
import {GoodReceiptScreen} from './pages/GoodReceipt/Screen'

import {ItemFulfillmentScreen} from './pages/ItemFulfillment/Screen'
import {InventoryAdjustmentScreen} from './pages/InventoryAdjusment/Screen'
import {TransferOrderScreen} from './pages/TransferOrder/Screen'

const InventoryScreen = {
  INVENTORY: {
    PATH: '/inventory',
    TITLE: 'Inventory',
    TITLE_ALT: 'Inventory',
    breadcrumbs: defaultBreadcrumbs,
  },

  ...ItemFulfillmentScreen,
  ...GoodReceiptScreen,
  ...InventoryAdjustmentScreen,
  ...TransferOrderScreen,
  ...DeliveryOrderScreen,
}

export default InventoryScreen
