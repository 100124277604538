import {FC} from 'react'
import {cn} from 'src/app/utils/cn-utils'
import GIcon from '../../../Icon/GIcon'
import {FormError} from '../FormError'
import {FormTextProps} from './Text.types'
import clsx from 'clsx'

export const FormText: FC<FormTextProps> = ({
  errorIsRp,
  className,
  inputClassName,
  error,
  touched,
  type = 'text',
  prependIcon,
  prependInnerIcon,
  appendIcon,
  appendInnerIcon,
  prependClassname,
  prependInnerClassname,
  appendClassname,
  appendInnerClassname,
  clickPrependIcon,
  clickPrependInnerIcon,
  clickAppendIcon,
  clickAppendInnerIcon,
  prependNode,
  appendNode,
  lastSuffix,
  firstSuffixIconsClassName,
  lastSuffixIconsClassName,
  firstSuffixIcons,
  lastSuffixIcons,
  firstSuffix,
  alertInformation,
  prefix,
  suffix,
  prependInnerIconRight,
  isClearWordingError,
  ...rest
}) => {
  return (
    <div className={className}>
      <div className='relative flex items-center'>
        {prependNode && <div className={cn('ml-2')}>{prependNode}</div>}
        {prependIcon && (
          <GIcon
            icon={prependIcon}
            className={cn('cursor-pointer mr-2 w-5 h-5', prependClassname)}
            onClick={clickPrependIcon}
          />
        )}
        <div className='relative flex items-center flex-grow'>
          {prependInnerIcon && (
            <GIcon
              icon={prependInnerIcon}
              className={cn('cursor-pointer absolute left-4 w-5 h-5', prependInnerClassname)}
              onClick={clickPrependInnerIcon}
            />
          )}
          {prefix && (
            <div
              data-testid='formtext-prefix'
              className='absolute w-5 h-5 font-medium left-4 text-fs-9'
            >
              {prefix}
            </div>
          )}
          {firstSuffixIcons && (
            <div className='inline-flex items-center h-[46px] px-4 bg-gray-200 border border-gray-300 rounded-none rounded-l-md'>
              <GIcon
                icon={firstSuffixIcons}
                className={cn('', firstSuffixIconsClassName)}
                onClick={clickPrependInnerIcon}
              />
            </div>
          )}
          {firstSuffix && (
            <span
              className={cn(
                'inline-flex items-center h-[46px] px-4 text-[1rem] text-gray-900 bg-gray-200 border border-gray-300 rounded-none rounded-l-md ',
                error && touched ? 'border-danger' : ''
              )}
            >
              {firstSuffix}
            </span>
          )}
          <input
            type={type}
            onWheel={() => {
              const activeElement = document.activeElement as HTMLInputElement
              activeElement && activeElement.blur()
            }}
            className={cn(
              touched && error
                ? 'border-danger focus:border focus:border-solid focus:border-danger'
                : '',
              prependInnerIcon || prefix ? 'pl-10' : '',
              appendInnerIcon || suffix ? 'pr-14' : '',
              inputClassName,
              firstSuffix || firstSuffixIcons ? 'rounded-none rounded-r-md border-l-0' : '',
              lastSuffix || lastSuffixIcons ? 'rounded-none rounded-l-md border-r-0' : ''
            )}
            {...rest}
          />
          {prependInnerIconRight && (
            <GIcon
              icon={prependInnerIconRight}
              className={cn('cursor-pointer absolute right-3 w-5 h-5', prependInnerClassname)}
              onClick={clickPrependInnerIcon}
            />
          )}
          {!appendInnerIcon && !alertInformation && !errorIsRp && touched && error && (
            <div className='absolute w-5 h-5 right-2'>
              <GIcon
                icon='IconWarningDanger'
                className={clsx('cursor-pointer absolute right-4 w-5 h-5', appendInnerClassname)}
              />
            </div>
          )}
          {suffix && (
            <div
              data-testid='formtext-suffix'
              className='absolute w-5 h-5 font-medium right-11 text-fs-9 text-[#344054]'
            >
              {suffix}
            </div>
          )}
          {appendInnerIcon && (
            <GIcon
              icon={appendInnerIcon}
              className={cn('cursor-pointer absolute right-4 w-5 h-5', appendInnerClassname)}
              onClick={clickAppendInnerIcon}
            />
          )}
        </div>
        {appendIcon && (
          <GIcon
            icon={appendIcon}
            className={cn('cursor-pointer ml-2 w-5 h-5', appendClassname)}
            onClick={clickAppendIcon}
          />
        )}
        {appendNode && <div className={cn('ml-2')}>{appendNode}</div>}
        {lastSuffix && (
          <span
            className={cn(
              'inline-flex items-center  h-[46px] px-4 text-[1rem] text-gray-900 bg-gray-200 border border-gray-300 rounded-none rounded-r-md ',
              error && touched ? 'border-danger' : ''
            )}
          >
            {lastSuffix}
          </span>
        )}
        {lastSuffixIcons && (
          <div className='inline-flex items-center h-[46px] px-4 bg-gray-200 border border-gray-300 rounded-none rounded-r-md'>
            <GIcon
              icon={lastSuffixIcons}
              className={cn('', lastSuffixIconsClassName)}
              onClick={clickPrependInnerIcon}
            />
          </div>
        )}
      </div>
      {isClearWordingError === undefined
        ? touched && error && <FormError>{error}</FormError>
        : null}
    </div>
  )
}
