import React, {useCallback, useEffect, useState} from 'react'
import {useHistory} from 'react-router'
import WarningModal from '../ModalCollection/WarningModal'

export type LeavePageBlockerProps = {
  when: boolean
  onOK?: () => boolean | Promise<boolean>
  onCancel?: () => boolean | Promise<boolean>
}

export function LeavePageBlocker({
  when,
  onOK = () => true,
  onCancel = () => false
}: LeavePageBlockerProps) {
  const history = useHistory()
  const [showPrompt, setShowPrompt] = useState(false)
  const [currentPath, setCurrentPath] = useState('')

  useEffect(() => {
    if (when) {
      history.block((prompt) => {
        setCurrentPath(prompt.pathname)
        setShowPrompt(true)
        return 'true'
      })
    } else {
      history.block(() => {})
    }

    return () => {
      history.block(() => {})
    }
  }, [history, when])

  const handleLeave = useCallback(async () => {
    if (onOK) {
      const canRoute = await Promise.resolve(onOK())
      if (canRoute) {
        history.block(() => {})
        history.push(currentPath)
      }
    }
  }, [currentPath, history, onOK])

  const handleCancel = useCallback(async () => {
    if (onCancel) {
      const canRoute = await Promise.resolve(onCancel())
      if (canRoute) {
        history.block(() => {})
        history.push(currentPath)
      }
    }
    setShowPrompt(false)
  }, [currentPath, history, onCancel])

  return showPrompt ? (
    <WarningModal
      show={showPrompt}
      handleClose={handleCancel}
      handleSuccess={handleLeave}
      header='Leave this page?'
      positiveLabel='Leave'
      negativeLabel='Cancel'
    >
      Changes you made may not be saved.
    </WarningModal>
  ) : null
}
